<template>
  <el-dialog
    title="上传工资条"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="50%"
  > 
      <div class="  myClient-top-10">
        <div  class="width-100 text-center">
          <el-upload
  class="upload-demo"
  drag
  action="https://jsonplaceholder.typicode.com/posts/"
  multiple>
  <i class="el-icon-upload"></i>
  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div> 
</el-upload>
        </div>
        
        <div class="width-100 text-center">导入须知：</div>
        <div  class="flex justify-content-center">
          
          <ul class="margin-auto">
            <li>
              1.导入前，请先到【工资条模板设置】中设置您机构的工资条导入模板
            </li>
            <li>2.导入表格中，员工姓名和手机号必须和系统中保持一致</li>
            <li>3.导入表格的表头必须与系统中设置的工资条模板保持一致</li>
            <li>
              4.工资条发送后，员工需要到移动端查询工资情况，PC端暂不支持查询
            </li>
            <li>5.一次最多只能导入1000条数据</li>
          </ul>
        </div>
      </div>
      <div class="flex justify-content-center"> 
        <el-button class="myClient-left-30" @click="close">关闭</el-button>
      </div> 
  </el-dialog>
</template>
<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      groupNmae: "",
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    close() {
      this.$emit("closepop");
    },
  },
};
</script>

<style>

</style>
