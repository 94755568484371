<template>
  <el-dialog
    title="工资条模板设置"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="30%"
  >
    <div class="myClient-top-10 padding">
      <table class="width-100">
        <thead class="width-100">
          <tr>
            <th>排序</th>
            <th>工资表头</th>
            <th>是否开启</th>
          </tr>
        </thead>
        <tbody class="width-100 text-align">
          <tr  class="height active">
            <td colspan="3">
              <span class="text-blue cursor" @click="addtable"
                >+添加自定义表头</span
              >
            </td>
          </tr>
          <tr
            :class="{ 'active height': index % 2 != 0 }"
            v-for="(item, index) in tableData"
            :key="index"
          >
            <th style="width: 15%">{{ index + 1 }}</th>
            <td style="width: 40%" v-if="item.state == 'del'">
              <el-input v-model="item.name" size="small "></el-input>
            </td>
            <td style="width: 55%" v-else>{{ item.name }}</td>
            <td style="width: 30%">
              <el-switch
                v-if="item.state != 'del'"
                v-model="item.state"
              ></el-switch>
              <span v-else class="text-blue cursor" @click="delState(item)"
                >删除</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-content-center">
      <el-button type="primary">确定</el-button>
      <el-button class="myClient-left-30" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      num: 1,
      dialogVisible: false,
      groupNmae: "",
      tableData: [
        {
          name: "王小虎",
          state: true,
        },
        {
          name: "王小虎",
          state: true,
        },
        {
          name: "王小虎",
          state: true,
        },
        {
          name: "王小虎",
          state: true,
        },
      ],
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    delState(item) {
      var p = this.tableData.filter((x) => {
        return x.name != item.name;
      });
      this.tableData = p;
      this.num--;
    },
    addtable() {
      if (this.num <= 5) {
        this.tableData.splice(1, 0, {
          name: "自定义" + this.num++,
          state: "del",
        }); 
      }
    },
    handleEdit(index, row) { 
    },
    handleDelete(index, row) { 
    },
    close() {
      this.$emit("closepop");
    },
  },
};
</script>

<style>
.text-align {
  text-align: center;
}
.active {
  background-color: #f5f5f5;
}
.height {
  height: 40px;
}
</style>
