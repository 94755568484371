<template>
  <el-dialog
    title="新增配置"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="50%"
  >
    <div class="myClient-top-10">
      <div>
        <div>
          <span class="step-icon">1</span>
          <span class="step-item-title">选择提成方式</span>
        </div>
        <div class="step-item-con">
          <el-radio-group v-model="radio">
            <p class="margin-top">
              <el-radio :label="3"
                >按教师课时：根据教师上课课时数计算课时费，公式：教师课时数X每课时金额</el-radio
              >
            </p>

            <p class="margin-top">
              <el-radio :label="2"
                >按学员课时：根据学员消耗课时数计算课时费，公式：学员课时数X每课时金额</el-radio
              >
            </p>
            <p class="margin-top">
              <el-radio :label="1"
                >按学费消耗：根据教师上课产生的学费消耗计算课时费，公式：学费消耗X提成比例</el-radio
              >
            </p>
          </el-radio-group>
        </div>
      </div>
      <div>
        <div>
          <span class="step-icon">2</span>
          <span class="step-item-title">确定每课时金额</span>
        </div>
        <div class="step-item-con">
          <el-radio-group v-model="radio1">
            <el-radio class="margin-top margin-left" :label="3.2"
              >固定金额</el-radio
            >
            <el-radio class="margin-top margin-left" :label="1.2"
              >梯度金额</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div>
        <div>
          <span class="step-icon">3</span>
          <span class="step-item-title">选择应用范围</span>
        </div>
        <div class="step-item-con">
          <p>
            <span class="status-point"></span>
            <span style="font-size: 12px">每个班课/一对一只能应用一个规则</span>
          </p>
          <el-button type="primary">选择班课/一对一</el-button>
        </div>
      </div>
      <div>
        <div>
          <span class="step-icon">4</span>
          <span class="step-item-title">消息输入</span>
        </div>
        <div class="step-item-con">
          <div class="flex flex-wrap-wrap">
            <div style="width: 10%">
              <span>规则名称</span>
            </div>

            <div style="width: 90%">
              <el-input
                style="width: 300px"
                v-model="rulename"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="margin-top flex flex-wrap-wrap">
            <div style="width: 10%">
              <span>备注</span>
            </div>

            <div style="width: 90%">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="remarks"
                show-word-limit
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-center">
      <el-button class="myClient-left-30" @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      remarks: "",
      rulename: "",
      radio1: "",
      radio: "",
      dialogVisible: false,
      groupNmae: "",
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    close() {
      this.$emit("closepop");
    },
  },
};
</script>

<style>
.step-icon {
  border-radius: 50%;
  background-color: #c4c7ce;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  position: absolute;
  left: -8px;
  text-align: center;
}
.step-item-title {
  color: #86919f;
  font-size: 16px;
  font-weight: normal;
  position: relative;
  padding-left: 20px;
}
.step-item-con {
  line-height: 30px;
  padding: 10px 0px 10px 25px;
  margin: 3px 0;
  border-left: 3px solid #c4c7ce;
  position: relative;
}
.status-point {
  background-color: #e6a23c;
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
