<template>
  <div class="total">
    <div class="configure">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="工资" disabled name="first1"> </el-tab-pane>
        <el-tab-pane label="工资条" name="first">
          <div>
            <div class="myClient-text myClient-top-10 margin-left-xs">
              <div>
                <el-button size="small" @click="Popup(1)">上传工资条</el-button>
                <el-button size="small" @click="Popup(2)"
                  >工资条模板设置</el-button
                >
              </div>
            </div>
            <div style="margin-top: 10px">
              <as-list :initdataList="datalist">
                <el-table-column
                  align="center"
                  prop="name"
                  label="发薪标题"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name1"
                  label="发薪月份"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="已发员工"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text5"
                  label="已确认员工"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text6"
                  label="评价日期"
                ></el-table-column>
                <el-table-column align="center" label="操作">
                  <span class="text-blue cursor">删除</span>
                </el-table-column>
              </as-list>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="绩效+薪资" name="admin">
          <div>
            <div class="myClient-top-10 margin-left-xs">
              <div class="flex flex-wrap-wrap">
                <div style="width: 80%">
                  <el-select v-model="Press" placeholder="请选择">
                    <el-option label="按上课校区" value="shool"> </el-option>
                    <el-option label="按上课班级" value="按上课校区">
                    </el-option>
                  </el-select>
                  <el-select
                    style="width: 400px"
                    v-model="multipleChoice"
                    multiple
                    placeholder="请选择"
                  >
                    <el-option label="全部校区" value="1"> </el-option>
                    <el-option label="总校区" value="2"> </el-option>
                  </el-select>
                  <span class="margin-left">上课日期&nbsp;</span>
                  <el-date-picker
                    v-model="date"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
                <div style="width: 20%" class="flex justify-content-flexEnd">
                  <el-button type="primary">计算</el-button>
                </div>
              </div>
              <div class="margin-top">
                <div class="flex flex-wrap-wrap padding-top padding-bottom">
                  <div style="width: 50%">
                    <el-button>导出</el-button>
                  </div>
                  <div style="width: 50%" class="flex justify-content-flexEnd">
                    <div class="work-title-search">
                      <el-input
                        placeholder="请输入员工姓名"
                        v-model="stuInput1"
                        class="input-with-select"
                        size="mini"
                      >
                        <el-button slot="append" icon="el-icon-search"
                          >搜索</el-button
                        >
                      </el-input>
                    </div>
                  </div>
                </div>

                <as-list :initdataList="datalist" :datacount="5">
                  <el-table-column
                    align="center"
                    prop="banji"
                    label="员工姓名"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="phone"
                    label="手机号"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="text3"
                    label="扣课时人次"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="text5"
                    label="学员课时"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="text6"
                    label="教师课时"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="text6"
                    label="学费消耗"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="text6"
                    label="教师课时费"
                  ></el-table-column>

                  <el-table-column
                  align="center"
                  prop="total"
                  label="薪资"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="total"
                  label="应发"
                ></el-table-column>
                  <el-table-column align="center" label="操作">
                    <span class="text-blue cursor">删除</span>
                  </el-table-column>
                </as-list>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="绩效设置" name="setting">
          <div class="padding-max" style="height: 300px">
            <el-button type="primary" @click="Popup(3)">新增配置</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <uploadSalary
      :Visible="editVisible && editType == 1"
      @closepop="closeEditPop"
    ></uploadSalary>
    <payslipTemplate
      :Visible="editVisible && editType == 2"
      @closepop="closeEditPop"
    ></payslipTemplate>

    <submissionMethod
      :Visible="editVisible && editType == 3"
      @closepop="closeEditPop"
    ></submissionMethod>
  </div>
</template>
<script>
import { fileType } from "@/config/index";
import uploadSalary from "../compinents/uploadSalary.vue";
import payslipTemplate from "../compinents/payslipTemplate.vue";
import submissionMethod from "../compinents/submissionMethod.vue";
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
    uploadSalary,
    payslipTemplate,
    submissionMethod,
  },
  data() {
    return {
      date: "",
      multipleChoice: ["1"],
      Press: "shool",
      activeName: "first",
      datalist: [
        {
          banji: "李玲",
          name: "2021-8月薪资",
          name1: "8月",
          text3: "0",
          text4: "0",
          text5: "0",
          text6: "0",
          text7: "0",
          
          phone:'15827654134',
          total:'0.00'
        },
        {
          banji: "任艾",
          name: "2021-7月绩效+薪资",
          name1: "7月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",          
          phone:'138766511111',
          total:'0.00'
        }, {
          banji: "李月",
          name: "2021-7月员工福利",
          name1: "7月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",          
          phone:'138766511111',
          total:'0.00'
        }, {
          banji: "学管主管周超",
          name: "2021-6月绩效+薪资",
          name1: "6月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0", 
          phone:'15938736111',
          total:'0.00'
        },, {
          banji: "张浩",
          name: "2021-5月绩效+薪资",
          name1: "5月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0", 
          phone:'1398717611',
          total:'0.00'
        }, {
          banji: "王昊",
          name: "2021-4月绩效+薪资",
          name1: "4月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
          phone:'15827261855',
          total:'0.00'
        }, {
          banji: "王昊",
          name: "2021-4月绩效+薪资",
          name1: "4月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
          phone:'15827261855',
          total:'0.00'
        },
        {
          banji: "欧阳成成",
          name: "2021-3月绩效",
          name1: "3月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
          phone:'15827261855',
          total:'0.00'
        },
        {
          banji: "胡杰",
          name: "2021-3月奖金合计",
          name1: "3月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
          phone:'15827261855',
          total:'0.00'
        },
        {
          banji: "杨志",
          name: "2021-2月年终奖",
          name1: "2月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
          phone:'15827261855',
          total:'0.00'
        },
        {
          banji: "吴晓辉",
          name: "2021-1月薪资",
          name1: "1月",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
          phone:'15827261855',
          total:'0.00'
        },
      ],
    };
  },
  methods: {
    Popup(type) {
      this.editVisible = true;
      this.editType = type;
    },
  },
};
</script>

<style lang="scss">
.color- {
  color: #8cc5ff;
}
.color-box {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}
.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>